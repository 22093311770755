<template>
  <b-modal 
    centered
    ref="modal"
    v-if="item"
    @shown="$refs.action && $refs.action.focus()"
  >
    <template #modal-title>
      <i class='bx bx-package'></i>
      {{item.barcode}}
      <status v-bind="{item}" />
    </template>

    <b-overlay :show="loading" rounded spinner-type="grow" spinner-variant="primary">

      <b-container class="bv-example-row">
        <b-row>
          <b-col :cols=6>
            <dl>
              <value label="shipments.consignee" icon="bx-purchase-tag" :value="item.$shipment.name" />
              <value label="common.phone" icon="bx-phone" :value="`+${item.$shipment.full_phone_number}`" />
              <value label="common.email" icon="bx-envelope" :value="item.$shipment.email" />
              <value label="packages.destination" icon="bx-map" :value="item.dropoff_location_name" />
            </dl>
          </b-col>
          <b-col :cols=6>
            <dl>
              <value label="merchants.label" icon="bx-cart" :value="item.$shipment.merchant_name" />
              <value label="warehouse.packages-in-shipment" icon="bx-package" :value="$tc(`packages.pluralize`,item.$shipment.package_count)" />
              <value v-if="inWarehouse" label="packages.location" icon="bx-building" :value="item.location_name" />
              <value v-if="inTransit" label="packages.driver" icon="bxs-user" :value="item.driver_name" />
            </dl>
          </b-col>
        </b-row>

        <router-link v-if="details" :to="{name: `packages-show`, params: {id: item.id, parent_id: item.shipment_id, exitModal: true}}" class="text-info float-right">
          {{$t('common.details')}} <i class='bx bx-link-alt'></i>
        </router-link>
      </b-container>

    </b-overlay>

    <template #modal-footer>
      <template v-if="action">
        <b-button :disabled="loading" @click="close(null)">{{$t('common.cancel')}}</b-button>
        <b-button :disabled="loading" ref="action" variant="primary" @click="perform()">{{$t(`warehouse.actions.${action}`)}}</b-button>
      </template>
      <b-button v-else :disabled="loading" @click="close(null)">{{$t('common.ok')}}</b-button>
    </template>
  </b-modal>
</template>

<script>
import Modal from "@/components/Modal"
import Value from "@/components/Value"
import Status from "@/components/Status"
import Package, { STATUS_IN_WAREHOUSE } from '@/resources/Package'
import api from '@/api'
import { mapState } from 'vuex'
import { STATUS_PICKED_UP } from '@/resources/Shipment'
import { accessCheck } from '@/router'

export default {
  name: 'WarehouseModal',
  extends: Modal,
  components: {Value,Status},
  data() {
    return {
      loading: false,
      details: accessCheck('packages-show')
    }
  },
  props: {
    item: {
      type: Package,
      required: true,
    },
  },
  computed: {
    ...mapState('warehouse',{
      locationId: 'locationId',
    }),
    action() {
      if(this.item.$meta.actions.checkin) {
        return 'checkin'
      } else if(this.item.$meta.actions.checkout) {
        return 'checkout'
      } else {
        return false
      }
    },
    inWarehouse() {
      return this.item.status === STATUS_IN_WAREHOUSE
    },
    inTransit() {
      return this.item.status === STATUS_PICKED_UP
    }
  },
  methods: {
    async perform() {
      this.loading = true
      await this[this.action]()
      this.loading = false
    },
    async checkin() {
      let confirm = true

      if(confirm) {
        await api.put(`warehouse/${this.locationId}/checkin/${this.item.id}`)
      }

      this.close(confirm ? 'checkin' : false)
    },
    async checkout() {
      const confirm = await this.$bvModal.msgBoxConfirm(this.$t('warehouse.actions.checkout-confirm'),{
        cancelTitle: this.$t('common.cancel'),
        okTitle: this.$t('warehouse.actions.checkout'),
        okVariant: 'danger'
      })

      if(confirm) {
        await api.put(`warehouse/${this.locationId}/checkout/${this.item.id}`)
        this.close('checkout')
      } else {
        this.close(false)
      }
    },
  }
}
</script>