<script>
import Store from './Store'
import WarehouseModal from '@/components/Modals/Warehouse.vue'
import { mapActions, mapState } from 'vuex'

export default {
  name: 'Warehouse',
  extends: Store,
  data() {
    return {
      icon: 'bx-building',
      namespace: 'warehouse',
      modal: WarehouseModal,
    }
  },
  computed: {
    ...mapState('warehouse',{
      locationId: 'locationId',
      locationName: 'locationName',
    }),
  },
  methods:  {
    ...mapActions('warehouse',{
      setLocation: 'setLocation',
      unsetLocation: 'unsetLocation',
    }),
  }
}
</script>